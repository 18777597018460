import React from 'react';
import './App.css';
import { FaDollarSign, FaTwitch, FaTwitter } from 'react-icons/fa';
import LoadingOverlay from 'react-loading-overlay-ts';
import SweetAlert2 from 'react-sweetalert2';


function App() {
  const [beatmapIds, setBeatmapIds] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [swalProps, setSwalProps] = React.useState({});

  const beatmapIdLimit = 25;

  const onChange = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    setBeatmapIds(e.currentTarget.value);
  };

  const download = async () => {
    await setSwalProps({ show: false });
    const ids = beatmapIds?.split('\n').map(id => id.trim()).filter(id => id !== '' && !isNaN(Number(id)));
    if (!ids || ids.length === 0 || beatmapIds === "") return;

    if (ids.length > beatmapIdLimit) {
      setSwalProps({
        show: true,
        title: 'Error',
        text: `You can only download up to ${beatmapIdLimit} beatmaps at once.`,
        icon: 'error'
      });
      return;
    }

    setLoading(true);

    fetch(`https://oba-server.paiva.codes/beatmaps/${ids.join(',')}/zip`)
      .then(res => res.json())
      .then(response => {
        setLoading(false);
        setBeatmapIds("");

        if (response.error) {
          setSwalProps({
            show: true,
            title: 'Error',
            text: response.error,
            icon: 'error'
          });
          return;
        }

        setSwalProps({
          show: true,
          title: 'Success',
          html: `Your file is ready for download!<br>Click <a href="${response.url}" target="_blank" rel="noopener noreferrer">here</a> to download it.`,
          icon: 'success'
        });
      })
  }

  const getBeatmapIdsCount = () => {
    if (!beatmapIds) return 0;

    return beatmapIds?.split('\n').map(id => id.trim()).filter(id => id !== '' && !isNaN(Number(id))).length;
  }

  return (
    <><SweetAlert2 {...swalProps} /><LoadingOverlay
      active={loading}
      spinner
      text='Downloading...'
    >
      <div className="App">
        <header className="App-header">
          <h1>osu! Beatmap Aggregator</h1>

          <p>
            Use this tool to download multiple beatmaps at once,
            without having to go through the osu! website.
          </p>

          <div className="beatmapIdsInput">
            <textarea value={beatmapIds} onChange={onChange} placeholder="Enter beatmap IDs here, separated by a new line." rows={15} />
            <p className={getBeatmapIdsCount() > beatmapIdLimit ? 'text-red' : ''}>{getBeatmapIdsCount()} / {beatmapIdLimit}</p>
          </div>

          <button onClick={download} disabled={loading}>Download</button>

          <div className="socials">
            <a href="https://twitter.com/jaxxibae" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://twitch.tv/jaxxibae" target="_blank" rel="noopener noreferrer">
              <FaTwitch />
            </a>
            <a href="https://ko-fi.com/jaxxibae" target="_blank" rel="noopener noreferrer">
              <FaDollarSign />
            </a>
          </div>
        </header>
      </div>
    </LoadingOverlay></>
  );
}

export default App;
